/** @format */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import 'channels'
import 'bootstrap'
import '../stylesheets/application'
import 'jquery'
import '@client-side-validations/client-side-validations/src'
import 'src/prism'
require('../src/admin')
require('../src/animation')
require('../src/app')
require('../src/company')
require('../src/imagesloaded')
require('../src/isotope')
require('../src/payment')
require('../src/rails.validations')
require('../src/rails.validations.simple_form')
require('../src/toast')
require('../src/share')

const firebaseConfig = {
	apiKey: 'AIzaSyByDeYNt83855BV1YSgho05rG-zh_WV4_Q',
	authDomain: 'etracking-service.firebaseapp.com',
	databaseURL: 'https://etracking-service.firebaseio.com',
	projectId: 'etracking-service',
	storageBucket: 'etracking-service.appspot.com',
	messagingSenderId: '302897837755',
	appId: '1:302897837755:web:200afaa4a06ed7d1bb1b9e',
	measurementId: 'G-SD5QREYL4M',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const images = require.context('../images', true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()
