/** @format */

showNavbar = (toggleId, navId, bodyId, headerId) => {
	var toggle = document.getElementById(toggleId),
		nav = document.getElementById(navId),
		bodypd = document.getElementById(bodyId),
		headerpd = document.getElementById(headerId);

	// Validate that all variables exist
	if (toggle && nav && bodypd && headerpd) {
		toggle.addEventListener('click', () => {
			// show navbar
			nav.classList.toggle('show-admin-ui');
			toggle.classList.toggle('bx-x');
			// add padding to body
			bodypd.classList.toggle('body-pd-admin-ui');
			// add padding to header
			headerpd.classList.toggle('body-pd-admin-ui');
		});
	}
};

colorLink = () => {
	if (linkColor) {
		linkColor.forEach((l) => l.classList.remove('active'));
		this.classList.add('active');
	}
};

document.addEventListener('DOMContentLoaded', function (event) {
	showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header');

	var linkColor = document.querySelectorAll('.nav_link');

	linkColor.forEach((l) => l.addEventListener('click', colorLink));
});
