/** @format */

setupMenuTrigger = () => {
	window.addEventListener('scroll', function () {
		var scroll = document.scrollingElement.scrollTop
		var box = document.querySelector('.header-text')?.offsetHeight || 0
		var header = document.querySelector('header')
		var headerHeight = header?.offsetHeight || 0

		if (scroll >= box - headerHeight) {
			header.classList.add('background-header')
		} else {
			header.classList.remove('background-header')
		}
	})

	document
		.querySelector('.menu-trigger')
		.addEventListener('click', function () {
			var headerNav = document.querySelector('.header-area .nav')
			this.classList.toggle('active')
			if (
				headerNav.style.display === 'none' ||
				headerNav.style.display === ''
			) {
				headerNav.style.display = 'block'
			} else {
				headerNav.style.display = 'none'
			}
		})
}

ckeckAgreePDPA = () => {
	let agreePDPA = JSON.parse(localStorage.getItem('agreePDPA'))
	let pdpa = document.getElementById('pdpa')
	if (agreePDPA) {
		pdpa.classList.add('d-none')
	} else {
		pdpa.classList.remove('d-none')
	}
}

agreePDPA = () => {
	localStorage.setItem('agreePDPA', JSON.stringify(true))
	document.getElementById('pdpa').classList.add('d-none')
}
