/** @format */

showToast = (message, color = 'bg-danger') => {
	let html = `<div class="toast ${color} text-white fade show" id="toast-100">
      <div class="toast-header ${color} text-white">
        <strong class="me-auto">
          <i class="bi-gift-fill"></i>
          ETrackings</strong>
        <button type="button" class="btn-close btn-close-white" onclick="closeToast('toast-100')"></button>
      </div>
      <div class="toast-body">
        ${message}
      </div>
    </div>`;

	let toastContainer = document.querySelector('.toast-container');
	toastContainer.innerHTML += html;

	setTimeout(() => {
		closeToast('toast-100');
	}, 8000);
};

closeToast = (id) => {
	let toast = document.getElementById(id);
	if (toast) toast.remove();
};
