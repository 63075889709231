/** @format */

setSelectDistrict = (el, key) => {
	fetch(`/addresses/${key}?id=${el.value}`, { method: 'GET' })
		.then((response) => {
			return response.json()
		})
		.then((resp) => {
			let selectEl = document.getElementById(`company_${key}_id`)
			let inputEl = document.getElementById('company_zipcode')
			inputEl.value = null
			selectEl.innerText = null
			resp.forEach((val) => {
				let option = new Option(val.name_th, val.id)
				if (key === 'sub_district') option.dataset.zipcode = val.zipcode
				selectEl.appendChild(option)
			})

			selectEl.disabled = false
		})
}

setZipcode = (el) => {
	let inputEl = document.getElementById('company_zipcode')
	inputEl.value =
		el?.options[el.selectedIndex]?.dataset?.zipcode || 'ไม่มีข้อมูล'
}
