/** @format */

isMobile = () => {
	return (is = {
		Android: function () {
			return navigator.userAgent.match(/Android/i);
		},
		BlackBerry: function () {
			return navigator.userAgent.match(/BlackBerry/i);
		},
		iOS: function () {
			return navigator.userAgent.match(/iPhone|iPad|iPod/i);
		},
		Opera: function () {
			return navigator.userAgent.match(/Opera Mini/i);
		},
		Windows: function () {
			return (
				navigator.userAgent.match(/IEMobile/i) ||
				navigator.userAgent.match(/WPDesktop/i)
			);
		},
		Macintosh: function () {
			return navigator.userAgent.match(/Macintosh/i);
		},
		any: function () {
			return is.Android() || is.BlackBerry() || is.iOS() || is.Opera();
		},
	});
};

checkIsMobile = (courier, trackingNumber, isNotAutoOpen = true) => {
	var is = isMobile();

	if (is.any()) {
		window.location.href = `etrackings://track?courier=${courier}&tracking-no=${trackingNumber}`;
	}

	if (isNotAutoOpen) {
		if (is.Macintosh()) {
			window.open('https://apps.apple.com/th/app/etrackings/id1523850639');
		} else if (is.Windows()) {
			window.open(
				'https://play.google.com/store/apps/details?id=com.saharak.manoo.etrackings'
			);
		}
	}
};

function checkIsAMobile(url) {
	var is = isMobile();

	try {
		if (is.any()) {
			window.location.href = url;
		} else {
			showToast('โปรดติดตั้งแอปพลิเคชัน เพื่อดำเนินการต่อ');
			window.open('https://apps.apple.com/th/app/etrackings/id1523850639');
			window.open(
				'https://play.google.com/store/apps/details?id=com.saharak.manoo.etrackings'
			);
		}
	} catch (e) {
		console.log(e);
	}
}

showBtnOpenApp = (input) => {
	let btn = document.getElementById('btnOpenApp')
	if (input.value) {
		btn.classList.remove('d-none')
	} else {
		btn.classList.add('d-none')
	}
}

packages = (productID) => {
	if (productID) {
		checkIsAMobile(`etrackings://packages?productID=${productID}`);
	} else {
		checkIsAMobile('etrackings://packages');
	}
};
